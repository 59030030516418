import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import logoImage from './../../../assets/logo/full-new-logo.svg';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
function Logo() {
    return (_jsx(Link, { to: "/dashboard/app", children: _jsx(Box, { component: "img", src: logoImage, sx: {
                minWidth: 160,
                height: 70,
                cursor: 'pointer',
                background: '#DAF6FF',
                pl: 2,
                py: 2,
                pr: 2,
                borderRadius: '10px 10px 20px 10px',
            } }) }));
}
export default Logo;
