import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// components
import Main from './Main';
import Header from './header';
// ----------------------------------------------------------------------
export default function DashboardLayout() {
    return (_jsxs(_Fragment, { children: [_jsx(Header, {}), _jsx(Box, { sx: {
                    display: { lg: 'flex' },
                    minHeight: { lg: 1 },
                }, children: _jsx(Main, { children: _jsx(Outlet, {}) }) })] }));
}
